import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserSearchResult } from '../models/user';
import { Observable, Subject, map, shareReplay, tap } from 'rxjs';
import { Papertypes } from '../models/papertypes';
import { Signingreasons } from '../models/signingreasons';
import { Signinglocations } from '../models/signinglocations';
import { OrganisationSettingsApiClient } from './organisation-settings.apiclient';
import { DefaultAllowedSafeTransferExtensions, PlatformSettings } from '../constants/settings.enum';

@Injectable({
  providedIn: 'root'
})

export class OrganisationApiClient {
  private API_ENDPOINT = environment.apiUrl + "/organisation/";
  private _organisationPlugins!: Observable<any[]>;
  private _enabledPluginsCache!: Observable<any[]> | null;
  emptyContactlistSubject = new Subject();
  emptyContactList: UserSearchResult[] = [];

  constructor(private http: HttpClient, private organisationSettingsApiClient: OrganisationSettingsApiClient) { }

  getStamp(w: number, h: number) {
    let utcOffset = (new Date()).getTimezoneOffset() * -1;
    let language = localStorage.getItem('language') && localStorage.getItem('language') != 'undefined' ? localStorage.getItem('language') : null;
    return this.http.post(this.API_ENDPOINT + sessionStorage.getItem('organisationGuid') + "/stamp", {
      width: w,
      height: h,
      language: language,
      utcOffsetMinutes: utcOffset
    }, { responseType: 'blob' });
  }

  getContacts(user: string, searchTerm: string) {
    if (user != null && user !== "" && user.length >= 3) {
      return this.http.get<UserSearchResult[]>(this.API_ENDPOINT + sessionStorage.getItem('organisationGuid') + "/contacts/" + searchTerm + "/" + user, {})
        .pipe(
          tap<UserSearchResult[]>((response: UserSearchResult[]) => {
            let r: UserSearchResult[];
            r = response
              .map(user => new UserSearchResult(user.firstname, user.prefix, user.lastname, user.email, user.mobile))
            return r;
          })
        );
    } else {
      this.emptyContactlistSubject.next(this.emptyContactList);
      return <Observable<UserSearchResult[]>>this.emptyContactlistSubject.asObservable();
    }
  }

  getPapertypes(selectedOrganisationGuid?: string) {
    return this.http.get<Papertypes>(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/papertypes");
  }

  setPapertypes(selectedOrganisationGuid: string, papertypes: any) {
    return this.http.post(this.API_ENDPOINT + selectedOrganisationGuid + "/papertypes", papertypes)
  }

  editPapertype(selectedOrganisationGuid: string, id: string, papertype: any) {
    return this.http.put(this.API_ENDPOINT + selectedOrganisationGuid + "/papertypes/" + id + "", papertype);
  }

  removePapertype(selectedOrganisationGuid: string, id: string) {
    return this.http.delete(this.API_ENDPOINT + selectedOrganisationGuid + "/papertypes/" + id);
  }

  changePapertypeOrder(selectedOrganisationGuid: string, papertypeOrder: Papertypes[]) {
    let p: Papertypes[] = [];
    for (let i: number = 0; i < papertypeOrder.length; i++) {
      p.push({
        id: papertypeOrder[i].id,
        name: papertypeOrder[i].name,
        ordinal: i,
        thumbnail: null
      });
    }
    return this.http.put(this.API_ENDPOINT + selectedOrganisationGuid + "/papertypes/changeorder", p);
  }

  getSigningLocations(selectedOrganisationGuid?: string) {
    return this.http.get<Signinglocations>(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signinglocations");
  }

  postSigningLocations(selectedOrganisationGuid: string, location: string) {
    return this.http.post<Signinglocations>(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signinglocations", { location: location });
  }

  deleteSigningLocation(selectedOrganisationGuid: string, id: number) {
    return this.http.delete(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signinglocations/" + id);
  }

  changeLocationOrder(selectedOrganisationGuid: string, orderLocations: Signinglocations[]) {
    let l: any[] = [];
    for (let i: number = 0; i < orderLocations.length; i++) {
      l.push({
        id: orderLocations[i].id,
        ordinal: i
      });
    }
    return this.http.post(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signinglocations/ChangeOrder", l)
  }

  getSigningReasons(selectedOrganisationGuid?: string) {
    return this.http.get<Signingreasons>(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signingreasons");
  }

  postSigningReason(selectedOrganisationGuid: string, reason: string) {
    return this.http.post<Signingreasons>(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signingreasons", { reason: reason });
  }

  deleteSigningReason(selectedOrganisationGuid: string, id: number) {
    return this.http.delete(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signingreasons/" + id);
  }

  changeReasonOrder(selectedOrganisationGuid: string, orderReasons: Signingreasons[]) {
    let r: any[] = [];
    for (let i: number = 0; i < orderReasons.length; i++) {
      r.push({
        id: orderReasons[i].id,
        ordinal: i
      });
    }
    return this.http.post(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/signingreasons/ChangeOrder", r)
  }

  getOrganisationPlugins(organisationGuid: string) {
    if (sessionStorage.getItem('organisationGuid') === organisationGuid) {
      return this.getOrganisationPluginsCache();
    } else {
      return this.getOrganisationPluginsNoneCache(organisationGuid);
    }
  }

  getOrganisationPluginsCache() {
    if (!this._organisationPlugins) {
      this._organisationPlugins = this.http.get<any[]>(this.API_ENDPOINT + sessionStorage.getItem('organisationGuid') + "/plugins").pipe(
        map(data => data),
        shareReplay(1)
      );
    }
    return this._organisationPlugins;
  }

  getOrganisationPluginsNoneCache(organisationGuid: string) {
    return this.http.get<any[]>(this.API_ENDPOINT + organisationGuid + "/plugins");
  }

  setPlugins(plugins: any, selectedOrganisationGuid: string) {
    if (sessionStorage.getItem('organisationGuid') === selectedOrganisationGuid) {
      this._enabledPluginsCache = null;
    }
    return this.http.put(this.API_ENDPOINT + (selectedOrganisationGuid ? selectedOrganisationGuid : sessionStorage.getItem('organisationGuid')) + "/plugins", plugins);
  }

  getEnabledPlugins(organisationGuid: string) {
    if (sessionStorage.getItem('organisationGuid') === organisationGuid) {
      return this.getEnabledPluginsCache(organisationGuid);
    } else {
      return this.getEnabledPluginsNonCache(organisationGuid);
    }
  }

  getEnabledPluginsCache(organisationGuid: string) {
    if (!this._enabledPluginsCache) {
      this._enabledPluginsCache = this.http.get<any[]>(this.API_ENDPOINT + organisationGuid + "/plugins/enabled").pipe(
        map(data => data),
        shareReplay(1)
      );
    }
    return this._enabledPluginsCache;
  }

  getEnabledPluginsNonCache(organisationGuid: string): Observable<any[]> {
    return this.http.get<any[]>(this.API_ENDPOINT + organisationGuid + "/plugins/enabled");
  }

  getOrganisationSettingsByFilter(id: string, filter: any[]) {
    if (sessionStorage.getItem('organisationGuid') === id) {
      return this.getOrganisationSettingsByFilterCache(id, filter);
    } else {
      return this.getOrganisationSettingsByFilterNoneCache(id, filter);
    }
  }

  getOrganisationSettingsByFilterCache(organisationGuid: string, filter: any[]) {
    return this.organisationSettingsApiClient.getOrganisationSettings(organisationGuid).pipe(map(settings => settings.filter(setting => filter.includes(setting.key))));
  }

  getOrganisationSettingsByFilterNoneCache(organisationGuid: string, filter: any[]) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = new HttpParams();
    params = params.append('settingNames', JSON.stringify(filter));
    return this.http.get(environment.apiUrl + "/organisations/" + organisationGuid + "/settingsByFilter", { headers: headers, params: params });
  }
  getAllowedSafeTransferDocumentExtensions(organisationGuid: string) {
    let allowedExtensions = DefaultAllowedSafeTransferExtensions;
    return this.getAdditionalSafeTransferDocumentExtensions(organisationGuid).pipe((map(additional => {
      if (additional) {
        allowedExtensions.forEach(add => additional.push(add));
      }
      return additional;
    })));
  }
  getAdditionalSafeTransferDocumentExtensions(id: string): Observable<string[]> {
    let fallbackEmpty: string[] = [];
    return this.getOrganisationSettingsByFilter(id, [PlatformSettings.AllowedDocumentExtensions]).pipe(map(
      ((settings: any) => {
        if (settings.findIndex((os: any) => os.key == PlatformSettings.AllowedDocumentExtensions) > -1) {
          return JSON.parse(settings[0]?.value.toLocaleLowerCase());
        };
        return fallbackEmpty;
      })
    ));
  }
}