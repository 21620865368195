import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { Crumb } from 'src/app/models/crumb';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CloudLocationsService } from 'src/app/services/cloudlocations.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';

export const CloudDocumentModalOptions: NgbModalOptions = {
  centered: true, 
  size: 'md', 
  backdrop: 'static'
};

export class BaseCloudDocumentModal {
  public isLoading = true;
  public loadDriveData: any;
  public selectedDirectory: Crumb[] = [];
  public provider!: string;
  public source!: string;
  public cloudProviderName!: string;
  public translations = <any>{};
  public isSharePoint: boolean = false;
  public isOneDrive: boolean = false;
  public isTeams: boolean = false;
  public isAfas: boolean = false;
  public isPinkwebdossier: boolean = false;
  public requestId!: string;
  public errorOccured: boolean = false;
  protected userGuid: string = "";  
  public url!: string;
  
  constructor(protected authenticationService: AuthenticationService, public activeModal: NgbActiveModal, protected cloudLocationsService: CloudLocationsService,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected translateParser: TranslateParser,
    protected loggingService: LoggingService) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
    this.authenticationService.currentUser.subscribe((data) => {
      this.userGuid = data.sub;
    });
  }
  
  getTranslations() {
    this.translateService.get([
      'CloudLocations.ProviderConnectError',
      'CloudLocations.Afas',
      'CloudLocations.Msgraph',
      'CloudLocations.Microsoft365',
      'CloudLocations.OneDrive',
      'CloudLocations.SharePoint',
      'CloudLocations.Teams',
      'CloudLocations.Pinkwebdossier',
      'Toasts.Error'
    ]).subscribe(translation => {
      this.translations.ProviderConnectError = translation['CloudLocations.ProviderConnectError'];
      this.translations.Afas = translation['CloudLocations.Afas'];
      this.translations.Msgraph = translation['CloudLocations.Msgraph'];
      this.translations.Microsoft365 = translation['CloudLocations.Microsoft365'];
      this.translations.OneDrive = translation['CloudLocations.OneDrive'];
      this.translations.SharePoint = translation['CloudLocations.SharePoint'];
      this.translations.Teams = translation['CloudLocations.Teams'];
      this.translations.Pinkwebdossier = translation['CloudLocations.Pinkwebdossier'];
      this.translations.error = translation['Toasts.Error'];
    });
  }

  changeProvider(provider: string, source: string) {
    this.provider = provider;
    this.source = source;
    if(this.provider == "afas") {
      this.cloudProviderName = this.translations.Afas;
    } else if (this.provider == "pinkwebdossier") {
      this.cloudProviderName = this.translations.Pinkwebdossier;
    }

    switch(this.source) {
      case "onedrive": {
        this.cloudProviderName = this.translations.Microsoft365 + " " + this.translations.OneDrive;
        break;
      }
      case "sharepoint": {
        this.cloudProviderName = this.translations.Microsoft365 + " " + this.translations.SharePoint;
        break;
      }
      case "teams": {
        this.cloudProviderName = this.translations.Microsoft365 + " " + this.translations.Teams;
        break;
      }
      default: {
        break;
      }
    }
    this.checkAccess();
  }

  checkAccess() {
    this.cloudLocationsService.getContents(this.provider, this.source).subscribe({
      next: (data: any) => {
        this.errorOccured = false;
        this.loadDriveData = data;
        if (this.loadDriveData) {
          this.isLoading = false;
          this.selectedDirectory = [];
        }
      },
      error: (error: HttpErrorResponse) => {
        this.errorOccured = true;
        if (this.provider == "msgraph" && error) {
          this.authorizeMSGraph();
        }
        if ((this.provider == "pinkwebdossier") && (error.status==412)) {
          this.activeModal.close();
          this.toastService.showError("" + this.translations.error + "", "" + this.translateParser.interpolate(this.translations.ProviderConnectError, { providerName: this.cloudProviderName }) + "")
        }
      }
    });
  }

  private authorizeMSGraph() {
    let clientid: string = "1a50f065-34cc-4cb7-9238-c238496d639f";
    this.url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id="+clientid+"&scope=offline_access%20Files.ReadWrite.All&response_type=code&redirect_uri=" +
    environment.apiUrl + "/api/external/msgraph/authenticatev2&state=" + this.userGuid;

    window.addEventListener("message", (event: any) => {
      let origin = event.origin.split('/');
      
      if (origin.length < 3 || origin[0] === "https:" && ((origin[2].indexOf("platformapi.pkisigning.io") > -1 ) || (origin[2].indexOf("localhost")>-1))) return;
      
      event.source.close();
      try{
        this.checkAccess();
      } catch(error:any) {
        this.loggingService.logException(error);
      }

    }, false);

    window.open(this.url, undefined, "popup=yes");   
  }

  public getRequestId(event: string) {
    //empty
  }

  public emitClose(event: any) {
    // empty
  }
}
