export enum DocumentTypeEnum {
    RegularPdf = 'RegularPdf',
    XbrlPublicationVersionAnnualReport = "XbrlPublicationVersionAnnualReport",
    XbrlAuditorsReport = 'XbrlAuditorsReport',
    XbrlAuditorsReportSFO = 'XbrlAuditorsReportSFO',
    XbrlAssessmentStatement = 'XbrlAssessmentStatement',
    XbrlCompositionStatement = 'XbrlCompositionStatement',
    XbrlStatutoryVersionAnnualReport = "XbrlStatutoryVersionAnnualReport",
    XbrlNexusVersionAnnualReport = "XbrlNexusVersionAnnualReport",
    GenericTextFile = 'GenericTextFile',
    RegularXml = "RegularXml",
    DetachedSignature = "DetachedSignature",
    XbrlPreparerExtension = "XbrlPreparerExtension",
    PdfAudittrail = "PdfAudittrail",
    DNBStaat = "DNBStaat",
    ReferencedDocument = "ReferencedDocument",
    Json = "Json",
    JsonSignature = "JsonSignature",
    VatDeclaration = "VatDeclaration",
    VatEuRecapitulativeStatement = "VatEuRecapitulativeStatement",
    IncomeTaxDeclaration = "IncomeTaxDeclaration",
    CorporateTaxDeclaration = "CorporateTaxDeclaration",
    SbrNexusSbvReport = "SbrNexusSbvReport",
    HTML = "HTML",
    SignatureStylesheet = "SignatureStylesheet",
    EnvelopingSignatureData = "EnvelopingSignatureData",

    // EXTEND WHEN MORE OPTIONS FOUND

}
export const XbrlDocumentTypes: DocumentTypeEnum[] = [
    DocumentTypeEnum.XbrlPublicationVersionAnnualReport,
    DocumentTypeEnum.XbrlAuditorsReport,
    DocumentTypeEnum.XbrlAuditorsReportSFO,
    DocumentTypeEnum.XbrlAssessmentStatement,
    DocumentTypeEnum.XbrlCompositionStatement,
    DocumentTypeEnum.XbrlStatutoryVersionAnnualReport,
    DocumentTypeEnum.XbrlNexusVersionAnnualReport,
    DocumentTypeEnum.SbrNexusSbvReport,
    DocumentTypeEnum.VatDeclaration,
    DocumentTypeEnum.VatEuRecapitulativeStatement,
    DocumentTypeEnum.IncomeTaxDeclaration,
    DocumentTypeEnum.CorporateTaxDeclaration
]