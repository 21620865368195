import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { byteSizePipe } from '../../pipes/byteSize.pipe';
import { ReadableDateComparedWithTodayPipe } from '../../pipes/readable-date-compared-with-today.pipe';
import { PhoneNumber31To0Pipe } from '../../pipes/phonenumber-31-to-0.pipe';
import { SortByPipe } from '../../pipes/sortby.pipe';
import { TextFilterPipe } from '../../pipes/textfilter.pipe';
import { TimeAgoPipe } from '../../pipes/timeAgo';
import { FilterPipe } from '../../pipes/filter.pipe';
import { PdfPreparationDialog } from '../../components/requestbuilder/pdf-preparation-dialog/pdf-preparation-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../../app.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InfoButtonComponent, DangerInfoButtonComponent } from 'src/app/components/info-button/info-button.component';
import { ToastsContainer } from 'src/app/components/general/toast/toast.component';
import { ActionToThePastTranslationKeyPipe } from 'src/app/pipes/action-to-the-past-translation-key.pipe';
import { ActionToTranslationKeyPipe } from 'src/app/pipes/action-to-translation-key.pipe';
import { StatusDisplayComponent } from 'src/app/components/status-display/status-display.component';
import { BadgeStatusDisplayComponent } from 'src/app/components/status-display/badge-status-display.component';
import { GreetUserComponent } from 'src/app/components/greet-user/greet-user.component';
import { ActionSummaryComponent } from 'src/app/components/action-summary/action-summary.component';
import { FormcontrolErrorMessagesComponent } from 'src/app/components/formcontrol-error-messages/formcontrol-error-messages.component';
import { ViewXbrlOrTxtModal } from 'src/app/modals/view-xbrl/view-xbrl-or-txt.modal';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { InputTrimDirective, TrimDirective } from 'src/app/directives/inputTrim.directive';
import { DisplayAmountBasedTranslation } from 'src/app/components/display-singular-plural-translation/display-amount-based-translation.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner.component.ts/loading-spinner.component';
import { PageSizePickerComponent } from 'src/app/components/page-size-picker/page-size-picker.component';
import { ViewXmlModal } from 'src/app/modals/view-xbrl/view-xml.modal';
import { MobileOverlayComponent } from 'src/app/components/general/mobile-overlay/mobile-overlay.component';
import { TagStringInputComponent } from 'src/app/components/general/tag-string-input/tag-string-input.component';
import { TagInputModule } from 'ngx-chips';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ReadableDateComparedWithTodayPipe,
    ActionToThePastTranslationKeyPipe,
    ActionToTranslationKeyPipe,
    TimeAgoPipe,
    byteSizePipe,
    PhoneNumber31To0Pipe,
    LoadingSpinnerComponent,
    TextFilterPipe,
    SortByPipe,
    FilterPipe,
    PdfPreparationDialog,
    ViewXbrlOrTxtModal,
    ViewXmlModal,
    InfoButtonComponent,
    DangerInfoButtonComponent,
    StatusDisplayComponent,
    BadgeStatusDisplayComponent,
    GreetUserComponent,
    ActionSummaryComponent,
    FormcontrolErrorMessagesComponent,
    BreadcrumbComponent,
    InputTrimDirective,
    TrimDirective,
    DisplayAmountBasedTranslation,
    PageSizePickerComponent,
    ToastsContainer,
    MobileOverlayComponent,
    TagStringInputComponent
  ], imports: [
    CommonModule,
    RouterModule, RouterOutlet, RouterLink, RouterLinkActive,
    NgbModule,
    FormsModule,
    AngularDraggableModule,
    NgxPanZoomModule,
    InfiniteScrollModule,
    TagInputModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    TagInputModule,
    ReadableDateComparedWithTodayPipe,
    TimeAgoPipe,
    byteSizePipe,
    ActionToThePastTranslationKeyPipe,
    ActionToTranslationKeyPipe,
    PhoneNumber31To0Pipe,
    TextFilterPipe,
    PageSizePickerComponent,
    SortByPipe,
    FilterPipe,
    ViewXbrlOrTxtModal,
    ViewXmlModal,
    FormcontrolErrorMessagesComponent,
    PdfPreparationDialog,
    InfoButtonComponent,
    DangerInfoButtonComponent,
    StatusDisplayComponent,
    BadgeStatusDisplayComponent,
    GreetUserComponent,
    ActionSummaryComponent,
    BreadcrumbComponent,
    ToastsContainer,
    DisplayAmountBasedTranslation,
    InputTrimDirective,
    LoadingSpinnerComponent,
    TrimDirective,
    MobileOverlayComponent,
    TagStringInputComponent

  ],
  providers: [ReadableDateComparedWithTodayPipe, DatePipe]
})
export class SharedModule { }
