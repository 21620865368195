import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoggingService } from 'src/app/services/logging.service';
import { IdentityBrandingService } from 'src/app/services/identityBranding.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PlatformHubService } from 'src/app/services/platformhub.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  oidcAuthority: string | undefined;
  whitelabelProperties: any;
  LogoImageSrc!: string;
  IdentityLogoImageSrc!: string;
  renderedDocument!:any;
  workgroups!: Object[];
  usersubscription!: Subscription;
  user!: User;
  showWidget: boolean = false;
  knowledgebase: boolean = true;
  enablev2firstuseslider: boolean = false;

  constructor(public router: Router, public oidcSecurityService: OidcSecurityService, @Inject(DOCUMENT) private docElements: Document, translate: TranslateService, private platformHub: PlatformHubService,
              private eventService: PublicEventsService, private meta: Meta, private titleService: Title, private loggingService: LoggingService,
              private identityBrandingService: IdentityBrandingService, private authenticationService: AuthenticationService) {
                this.getDeploymentSlot();
    this.oidcSecurityService.getConfiguration().subscribe((result) => this.oidcAuthority = result.authority);
    
    let currentdomain: string = document.location.href.split('/')[2];
    let configWLAuthorityUrl = sessionStorage.getItem('authority') ? sessionStorage.getItem('authority') : "";
    let configAuthorityUrl = this.oidcAuthority ? this.oidcAuthority : environment.identityUrl;
    let defaultAPIUrl = environment.apiUrl;
    let defaultAuthUrl = environment.identityUrl;
    let cspHeader = "default-src 'self' "+defaultAPIUrl+"; ";
      cspHeader += "script-src 'self' 'unsafe-inline' 'unsafe-eval' data: https://ajax.googleapis.com https://cdn.acc.nl https://ph9hrcqkb03m.statuspage.io https://jsd-widget.atlassian.com; "
      cspHeader += "style-src 'self' 'unsafe-inline' https://fonts.googleapis.com https://ajax.googleapis.com https://cdn.acc.nl; "
      cspHeader += "img-src 'self' data: blob: https://pkisigning.nl/ https://www.gravatar.com/ "+configAuthorityUrl+" https://www.quovadisglobal.com https://cdn.digidentity.eu https://cdn.acc.nl https://www.digicert.com https://pki.acc.cleverbase.com https://sign.prd.itsme.services https://"+currentdomain+"; "
      cspHeader += "connect-src 'self' "+defaultAPIUrl+" " + defaultAuthUrl + " "+configAuthorityUrl+" "+configWLAuthorityUrl+" "+environment.signingApiUrl+" https://pkisigning.service.signalr.net wss://pkisigning.service.signalr.net https://dc.services.visualstudio.com https://api.statuspage.io https://www.pkisigning.nl https://pkisigning.nl; "
      cspHeader += "font-src 'self' data: https://fonts.googleapis.com https://fonts.gstatic.com; "
      cspHeader += "child-src https://ph9hrcqkb03m.statuspage.io https://api.statuspage.io "+configAuthorityUrl+" "+configWLAuthorityUrl+" https://"+currentdomain+";"
      
    let i  = 0;
    let tim = setInterval(() => {
      let tag = this.meta.getTag('http-equiv=Content-Security-Policy');
      if (tag) {
        this.meta.removeTag('http-equiv=Content-Security-Policy');
        this.meta.addTag({ 'http-equiv': 'Content-Security-Policy', content: cspHeader });
      }
      if (i == 1) { clearInterval(tim) };
      i++;
    }, 1000);

    translate.addLangs(["nl","en"]);
    translate.setDefaultLang('nl');
    let localLang = localStorage.getItem('language')?.toLocaleLowerCase();
    let browserLang: string = translate.getBrowserLang()!.toLocaleLowerCase();
    if (localLang) {
      translate.use(localLang);
      this.docElements.documentElement.lang = localLang;
    } else {
    if(translate.getLangs().indexOf(browserLang) > -1)
      translate.use(browserLang);
      this.docElements.documentElement.lang = browserLang;
    }
    
    document.addEventListener("gesturestart", function (e) {
      e.preventDefault();
    });
    document.addEventListener("gesturechange", function (e) {
      e.preventDefault();
    });
    document.addEventListener("gestureend", function (e) {
      e.preventDefault();
    });

    this.usersubscription = this.authenticationService.currentUser.subscribe((data) => {
      this.user = data;
      let jsdWidget = document.getElementById('jsd-widget');
      if(data.role && data.role.indexOf("Customer") > -1 && jsdWidget) {
        jsdWidget.style.display = 'inline-block';
        jsdWidget.style.height = '80px';
        jsdWidget.style.width = '180px';
        jsdWidget.style.right = '-10px';
      }
      jsdWidget?.classList.add('me-2','me-sm-2','me-md-4','me-lg-5','me-xl-5','me-xxl-5');
    });
  }

  ngOnInit() {
    this.setWhitelabel();
    this.titleService.setTitle('PKIsigning');
    let s = this.eventService.registerForEvents().pipe(filter((notification) => notification.type === EventTypes.CheckingAuthFinished)).subscribe({
      next: () => { 
        if (sessionStorage.getItem("returnUrl")?.split('/')[1] !== 'view') {
          this.platformHub.start(true).catch((error) => this.loggingService.logException(error));
        }
        s.unsubscribe();
      }
    });
    this.eventService.registerForEvents().pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult)).subscribe({
      next: () => {
        this.oidcSecurityService.getPayloadFromIdToken().subscribe(payload=>{
          let workgroups: object[] = [];
          if(typeof(payload?.Workgroup) === "string") workgroups.push(JSON.parse(payload.Workgroup));
          if(typeof(payload?.Workgroup) === "object") payload.Workgroup.forEach((workgroup: any) => workgroups.push(JSON.parse(workgroup)));
          this.checkForWorkgroupsUpdate(workgroups);
        });
      }
    });
  }

  public setWhitelabel() {
    let url: string = window.location.host.toLowerCase();
    if (url === "localhost:4200") { url = "whitelabeldev.pkisigning.io"; }
    else if (url === "platform.pkisigning.io") { url = "platform.pkisigning.nl"; }
    this.identityBrandingService.getWhitelabelSettings(url).subscribe((data: any) => {
      this.whitelabelProperties = data;
      for (let i: number = 0; i < this.whitelabelProperties.length; i++) {
        switch(this.whitelabelProperties[i].name) {
          case "companyname":  
            if (this.whitelabelProperties[i].value !== null) this.titleService.setTitle(this.whitelabelProperties[i].value);
            else this.titleService.setTitle('PKIsigning');
            break;
          case "platformLogo":
            if (this.whitelabelProperties[i].value !== null) this.LogoImageSrc = new URL(this.whitelabelProperties[i].value, environment.identityUrl).href;
            else this.LogoImageSrc = '../assets/PKIsigning_logo.png';
            break;
          case "identityLogo":
            if (this.whitelabelProperties[i].value !== null) this.IdentityLogoImageSrc = new URL(this.whitelabelProperties[i].value, environment.identityUrl).href;
            else this.IdentityLogoImageSrc = '../assets/PKIsigning_logo_color.png';
            break;
          case "favicon":
            if (this.whitelabelProperties[i].value !== null) this.setFavicon(new URL(this.whitelabelProperties[i].value, environment.identityUrl).href);
            else this.setFavicon('../assets/PKIsigning_favicon.png');
            break;
          case "knowledgebase":
            if (this.whitelabelProperties[i].value !== null) this.knowledgebase = JSON.parse(this.whitelabelProperties[i].value.toLowerCase());
            else this.knowledgebase = true;
            break;
          case "enablev2firstuseslider": 
            if (this.whitelabelProperties[i].value !== null) this.enablev2firstuseslider = JSON.parse(this.whitelabelProperties[i].value.toLowerCase());
            else this.enablev2firstuseslider = false;
            break;
          case "enabled":
          case "error":
          case "warning":
          case "background":
          case "contrast":
          case "natural":
          case "action":
          case "button":
          case "email":
          case "emailHeader":
          case "emailheaderV2":
          case "emailFooter":
          case "emailfooterV2":
          case "companyName":
          case "smssender":
          case "frontendDomain":
          case "domain":
            break;
          default:
            document.documentElement.style.setProperty('--' + this.whitelabelProperties[i].name, this.whitelabelProperties[i].value);
            break;
        }
      }
      switch(url) {
        case "devplatform.pkisigning.io":
        case "devplatformv2.pkisigning.io":
        case "testplatform.pkisigning.io":
        case "testplatformv2.pkisigning.io":
        case "accplatform.pkisigning.io":
        case "platform.pkisigning.io":
        case "platform.pkisigning.nl":
          this.enablev2firstuseslider = true;
          break;
        default:
          break;
      }
    });
  }

  private setFavicon(src: string) {
    let link = document.createElement('link');
    link.rel = 'shortcut icon';
    link.href = src;
    document.head.appendChild(link);
  }

  getDeploymentSlot() {
	  if(document.location.search.indexOf("x-ms-routing-name")>-1){
      let search = document.location.search;
      search = search.substring(1,search.length);
      let params = search.split('&');
      for(let i=0;i<params.length;i++)
      {
        let param = params[i].split('=');
        if(param[0]==="x-ms-routing-name"){
          document.cookie="slot="+param[1]+"; SameSite=Lax; Secure; max-age=" + 60*60*2;
          break;
        }
      }
    }
  }

  private checkForWorkgroupsUpdate(payloadWorkgroups: Object[] = []){
    if(this.workgroups && this.workgroups.length != payloadWorkgroups.length) this.platformHub.loadRequests();
    
    this.workgroups = payloadWorkgroups;
  }
}
