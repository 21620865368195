/**
 * The Extensions that are always allowed for 'Safe Transfer'
 * Example: pdf, docx
 */
export const  DefaultAllowedSafeTransferExtensions: string[] = ['pdf', 'xbrl', 'xps', 'xsd', 'doc', 'docx'];


export enum PlatformSettings {
  EnableSpecialistRequestWorkflows = 'EnableSpecialistRequestWorkflows',
  ShowLocation = 'ShowLocation',
  ShowReason = 'ShowReason',
  UseInternalStamp = 'UseInternalStamp',
  UseExternalStamp = 'UseExternalStamp',
  UseInternalDraw = 'UseInternalDraw',
  UseExternalDraw = 'UseExternalDraw',
  UseInternalPicture = 'UseInternalPicture',
  UseExternalPicture = 'UseExternalPicture',
  OrganisationLanguage = 'OrganisationLanguage',
  EmailSender = 'EmailSender',
  ShowRecentlyContacts = 'ShowRecentlyContacts',
  SigningNote = 'SigningNote',
  EmailInviteReminderSchedule = 'EmailInviteReminderSchedule',
  EmailOwnerDocumentReminderSchedule = 'EmailOwnerDocumentReminderSchedule',
  XbrlRenderClient = 'xbrlRenderClient',
  AllowedDocumentExtensions = 'allowedDocumentExtensions',

  // Email Notify settings:
  EmailOwnerDigipoortStatus = 'EmailOwnerDigipoortStatus',
  EmailOwnerDocumentExpires = 'EmailOwnerDocumentExpires',
  EmailOwnerSignDone = 'EmailOwnerSignDone',
  EmailOwnerSignReminder = 'EmailOwnerSignReminder',
  EmailSignerExSignReminder = 'EmailSignerExSignReminder',
  EmailSignerIDigipoortStatus = 'EmailSignerIDigipoortStatus',
  EmailSignerISignDone = 'EmailSignerISignDone',
  EmailSignerISignReminder = 'EmailSignerISignReminder',
  EmailOwnerSignExpired = 'EmailOwnerSignExpired',

  // AFAS:
  UseAFAS = 'UseAFAS',
  AfasConnectorBaseUrl = 'AfasConnectorBaseUrl',
  AfasConnectorToken = 'AfasConnectorToken',
  AfasFTP = 'AfasFTP',
  AfasFTPMultipleFilesOffered = 'AfasFTPMultipleFilesOffered',
  AfasFTPUser = 'AfasFTPUser',
  AfasFTPUserId = 'AfasFTPUserId',
  UseClientOnline = 'UseClientOnline',
  PinkwebUrl = 'PinkwebUrl',
  PinkwebUsername = 'PinkwebUsername',
  PinkwebPassword = 'PinkwebPassword',
  UseSimplicateCrmData = 'UseSimplicateCrmData',
  SimplicateApiKey = 'SimplicateApiKey',
  SimplicateApiSecret = 'SimplicateApiSecret',
  SimplicateApiSubdomain = 'SimplicateApiSubdomain',
  DigipoortEnvironment = "DigipoortEnvironment",
  SBRNexusEnvironment = "SBRNexusEnvironment",
  AansluitSuiteAanleverKenmerk = "AansluitSuiteAanleverKenmerk",

  // Salesforce:
  UseSalesforceCrmData = 'UseSalesforceCrmData',
  SalesforceApiKey = 'SalesforceApiKey',
  SalesforceApiSecret = 'SalesforceApiSecret',
  SalesforceApiSubdomain = 'SalesforceApiSubdomain',
}

export enum IdentitySettings {

}



export enum OrganisationPlugins {
  AFAS = 'AFAS',
  AFASInSite = 'AFASInSite',
  PinkwebAccordering = 'PinkwebAccordering',
  PinkwebDossier = 'PinkwebDossier',
  AuditCase = 'AuditCase',
  CaseWare = 'CaseWare',
  MAP = 'MAP',
  SharePoint = 'SharePoint',
  OneDrive = 'OneDrive',
  Teams = 'Teams'
}