<ng-container>
  <div class="d-none d-md-block" >
    <div class="page-container" #pagelist>
      <div *ngFor="let pageSize of pageSizes; let pId=index; first as first" (load)="first ? loadPageData(): '';" class="page-image">
        <canvas *ngIf="!isPagePreloaded(pId+1);" [style.aspectRatio]="aspectRatio(pageSize.X,pageSize.Y)" style="width:100%; background-color: white;" id="{{pId+1}}_indicator"></canvas>
        <img *ngIf="isPagePreloaded(pId+1);" [style.aspectRatio]="aspectRatio(pageSize.X,pageSize.Y)" style="width:100%"  [src]="getPageUrl(pId+1)" id="{{pId+1}}_indicator" alt="Page{{pId+1}}" class="page-image">
      </div>
      <ng-container *ngFor="let signatureField of signaturefields; let i=index">
        <div class="grid grid-start" *ngIf="displayfields && signatureField.readonly" [ngClass]="{'signatureField':true,'readonly':signatureField.readonly}" 
        [ngStyle]="{'position': 'absolute','top' : signatureField.y+'px', 'left': signatureField.x+'px'}"
        id="{{signatureField.name}}">
          <div class="child disabled">
            <div class="placeholder-field disabled" [style.width.px]="signatureField.width" [style.height.px]="signatureField.height">
              <i class="bi bi-grip-vertical grip-placeholder-field" aria-hidden="true"></i>
              <span *ngIf="signatureField.width < 140">{{signatureField.acronym}}</span>
              <span *ngIf="signatureField.width >= 140">{{signatureField.fullname}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    
    <div class="panzoom-div">
      <div cdkDropListGroup class="panzoom-listgroup" id="panzoomList" [style.marginTop.px]="-TOP_MARGIN" [style.marginBottom.px]="TOP_MARGIN">
        <ng-container *ngFor="let signatureField of signaturefields; let i=index">
          <div class="grid grid-start active pointer-events-auto user-select-none {{signatureField.name}}" *ngIf="displayfields && !signatureField.readonly" 
              [ngDraggable]
              (stopped)="draggingStopped($event, signatureField.actorId)" 
              [ngClass]="{'signatureField':true, 'animated':true,'zoomInRight':true,'readonly':signatureField.readonly}"
              [position]="{'y': signatureField.y, 'x': signatureField.x}"
              [ngStyle]="{'position': 'absolute'}"
              [bounds]="pagelist" 
              [inBounds]="true"
              (movingOffset)="onSignatureMoving()"
              id="activePlaceholderField"
              [style.width.px]="signatureField.width" 
              [style.height.px]="signatureField.height"
              #signature>
            <div id="gridlineHorizontalTop" *ngIf="isDraggable"></div>
            <div id="gridlineVerticalLeft" *ngIf="isDraggable"></div>
            <div class="placeholder-field child active"
                *ngIf="!signatureField.readonly" 
                id="{{signatureField.name}}"
                ngResizable 
                (rzResizing)="onSignatureResizing($event)"
                (rzStop)="signatureResized($event, signatureField.actorId)" 
                [rzAspectRatio]="true" 
                [rzMinWidth]="90"
                rzHandles="se"
                [style.width.px]="signatureField.width" 
                [style.height.px]="signatureField.height">
              <i class="bi bi-grip-vertical grip-placeholder-field" aria-hidden="true"></i>
              <span *ngIf="resizableSignatureWidth < 140">{{signatureField.acronym}}</span>
              <span *ngIf="resizableSignatureWidth >= 140">{{signatureField.fullname}}</span>
            </div>
            <div id="gridlineHorizontalBottom" *ngIf="isDraggable"></div>
            <div id="gridlineVerticalRight" *ngIf="isDraggable"></div>
          </div>
        </ng-container>
      </div> 
    </div>

    <!-- navigation bar-->
    <div class="modal-footer d-flex justify-content-center modal-footer--sticky">
      <button type="button" class="btn btn-sm btn-outline-primary btn-shadow me-2" (click)="close()" translate>Button.Back</button>
      <div class="btn-group btn-sm btn-shadow me-2">
        <button type="button" [disabled]="pageId===1" class="btn btn-sm pdf-preparation-dialog-buttons-border text-primary" (click)="scrollToPage(pageId-1);"><em class="bi bi-caret-left-fill"></em></button>
        <div class="btn-group btn-sm" ngbDropdown placement='top-start' role="group" aria-label="Button group with nested dropdown" [ngClass]="{'pagination-setplaceholder-wider': !place}">
          <button type="button" class="btn btn-sm btn-primary" ngbDropdownToggle>{{pageId}}/{{pageSizes.length}}</button>
          <div class="dropdown-menu dropdown-menu-pdf-preparation-dialog"  ngbDropdownMenu>
            <button ngbDropdownItem *ngFor="let page of [].constructor(pageSizes.length); let pId=index" (click)="scrollToPage(pId+1);" class="btn-sm">{{pId+1}}</button>
          </div>
        </div>
        <button type="button" [disabled]="pageId===pageSizes.length" class="btn btn-sm pdf-preparation-dialog-buttons-border text-primary" (click)="scrollToPage(pageId+1);"><em class="bi bi-caret-right-fill"></em></button>
      </div>
      <div class="btn-group btn-sm btn-shadow me-2">
        <button type="button" [disabled]="currentZoomLevel==0" class="btn btn-sm pdf-preparation-dialog-buttons-border text-primary" (click)="zoomOut()"><em class="bi bi-zoom-out"></em></button>
        <button type="button" [disabled]="currentZoomLevel==zoomLevels.length-1" class="btn btn-sm pdf-preparation-dialog-buttons-border text-primary" (click)="zoomIn()"><em class="bi bi-zoom-in"></em></button>
      </div>
      <button *ngIf="(request.status == 'New' || request.status == 'Active') && place && !setMoreSignaturefields" type="button" class="btn btn-sm btn-success btn-shadow pdf-preparation-dialog-buttons" (click)="saveSignatureField()" translate>Button.Place</button>
      <button *ngIf="(request.status == 'New' || request.status == 'Active') && place && setMoreSignaturefields" type="button" class="btn btn-sm btn-primary btn-shadow pdf-preparation-dialog-buttons" (click)="saveSignatureField()" translate>Button.Next</button>
      <button *ngIf="(request.status == 'New' || request.status == 'Active') && !place && showEditDocument" type="button" class="btn btn-sm btn-secondary btn-shadow pdf-preparation-dialog-buttons" (click)="editDocument()" translate>Button.Edit</button>
    </div>
    <!-- end navigation bar -->

  </div>
</ng-container>